import React from 'react';
import ReactDOM from 'react-dom';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from '../src/SBI/azureAuth/authConfig';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Router from './Router/Router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux';
import '../src/SBI/style/global.scss';
import './index.scss';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_fr from './SBI/translation/fr/common.json';
import common_en from './SBI/translation/en/common.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
  },
});

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <I18nextProvider i18n={i18next}>
            <Router />
          </I18nextProvider>
        </React.StrictMode>
      </PersistGate>
    </Provider>
  </AzureAD>,
  document.getElementById('root')
);
// ReactDOM.render(
//   <React.StrictMode>
//       <Router />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
