
const initialState = {
    userName:'',
    userOccupation: '',
    basicInformation: {},
    businessAddress: {},
    stepTwo: {
      validLicense: '',
      applianceMounting: '',
      contractualDisputes: '',
      qualifiedCPA: '',
    },
    stepThree: {
      policyStartDate: '',
      generalLiabilityPrice: 0,
      professionalLiabilityPrice: 0,
      businessPropertyPrice: 0,
      businessToolsCoverage: 0,
    },
    stepFour:{
      
    },
    pricing: null,
    coverages:null,
    submission: null,
    policy: null,
    partyId: '',
    error: null,
  };
  
  const defaultState = { ...initialState };
  
  const resetAllUserInfoStates = () => ({ ...defaultState });
  const updateUserOccupation = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      userOccupation: payload,
    };
  };
  const updateBasicInformation = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      basicInformation: payload,
    };
  };
  const updateBusinessAddress = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      businessAddress: payload,
    };
  };
  const setStepThree = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      stepThree: payload,
    };
  };

  const setStepTwoAccount = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      stepTwo: {...state.stepTwo ,qualifiedCPA: payload},
    };
  };

  const setStepTwoElectrician = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      stepTwo: {...state.stepTwo ,applianceMounting: payload},
    };
  };

  const AD_userInfo = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      userName: payload,
    };
  };

  const updateSubmission = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      submission: payload,
    };
  };

  const updatePricing = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      pricing: payload,
    };
  };

  const updateCoverage = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      coverages: payload,
    };
  };

  const updatePolicy = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      policy: payload,
    };
  };

  const updatepartyId = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      partyId: payload,
    };
  };

  const userInfo = (state = initialState, action) => {
    switch (action.type) {
      case 'RESET_ALL_USER_INFO_STATES':
        return resetAllUserInfoStates();
      case 'UPDATE_USER_OCCUPATION':
        return updateUserOccupation(state, action);
      case 'UPDATE_BASIC_INFO':
          return updateBasicInformation(state, action);
      case 'UPDATE_BUSINESS_ADDRESS':
        return updateBusinessAddress(state, action);
      case 'UPDATE_STEP_THREE':
          return setStepThree(state, action);
      case 'UPDATE_STEP_TWO_ACCOUNTANT':
        return setStepTwoAccount(state, action);
      case 'UPDATE_STEP_TWO_ELECRICIAN':
        return setStepTwoElectrician(state, action);
      case 'UPDATE_SUBMISSION':
        return updateSubmission(state, action);
      case 'UPDATE_COVERAGE':
        return updateCoverage(state, action);
      case 'UPDATE_PRICING':
        return updatePricing(state, action);
      case 'UPDATE_POLICY':
        return updatePolicy(state, action);
      case 'USERNAME':
        return AD_userInfo(state, action);
        case 'PARTYID':
        return updatepartyId(state, action);
      default:
        return state;
    }
  };
  
  export default userInfo;
  