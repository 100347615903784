import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer } from 'redux-persist';
import userInfo from './userInfo';
import PolicyInfo from './PolicyInfo';

const rootReducer = combineReducers({
  userInfo,
  PolicyInfo,
});

const persistConfig = {
  key: 'root',
  storage,
};

export default persistReducer(persistConfig, rootReducer);
