import React, { Component } from 'react';
import { Header, Tab, NeedHelp } from '../../sharedComponents';
import { NexusRadio, NexusInput, NexusCheckbox } from '@nexus/react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Service from '../../sharedComponents/Service';
import '../../style/StepThreeStyle.scss';
import { withTranslation } from "react-i18next";
class Accounted extends Component {
  constructor() {
    super();
    this.state = {
      policyStartDate: '',
      generalLiabilityPrice: 0,
      professionalLiabilityPrice: 0,
      businessPropertyPrice: 0,
      businessToolsCoverage: 0,
      firstboxChecked: false,
      checkedFirsRadio: false,
      secondtboxChecked: false,
      checkedSecondRadio: false,
      thirdtboxChecked: false,
      businessToolsCoverageCheckBox: false,
      submissionId: null,
    };
  }

  service = new Service();
  componentDidMount() {
    // localStorage.setItem('token', 'remove-token');
    this.tokenAPI();
  }

  tokenAPI() {
    this.service.getAuthToken();
  }

  setBackgroundColor(flag) {
    this.setState({ firstboxChecked: flag.target.checked });
    /*  if (flag.target.checked === false) {
             this.setState({ generalLiabilityPrice: '$ 0' });    
             this.setState({checkedFirsRadio: false});        
         } */
  }
  setGeneralLiability(value) {
    this.setState({ generalLiabilityPrice: value });
    this.setState({ checkedFirsRadio: true });
  }

  setBackgroundColor2ndBox(flag) {
    this.setState({ secondtboxChecked: flag.target.checked });
    /* if (flag.target.checked === false) {
            console.log(flag.target.checked)
            this.setState({ professionalLiabilityPrice: '$ 0' });
            this.setState({checkedSecondRadio: false});
        } */
  }
  setProfessionalLiability(value) {
    this.setState({ professionalLiabilityPrice: value });
    this.setState({ checkedSecondRadio: true });
  }

  setBackgroundColor3rdBox(flag) {
    this.setState({ thirdtboxChecked: flag.target.checked });
    /*  if (flag.target.checked === false) {
             console.log(flag.target.checked)
             this.setState({ businessPropertyPrice: '$ 0' });
             if(this.state.businessToolsCoverageCheckBox){
                 this.setState({ businessToolsCoverage: '$ 0' });
             }
             
         } */
  }
  setbusinessPropertyPrice(value) {
    this.setState({ businessPropertyPrice: value });
  }
  setbusinessToolsCoverage(event) {
    this.setState({ businessToolsCoverageCheckBox: event });
    if (event) {
      this.setState({ businessToolsCoverage: '$ 10.000' });
    } else {
      this.setState({ businessToolsCoverage: '$ 0' });
    }
  }

  navigateToPrevPage() {
    this.props.navigate('/sbi/step-two-accountant');
  }

  nextPage() {
    const { dispatch } = this.props;
    const {
      policyStartDate,
      generalLiabilityPrice,
      professionalLiabilityPrice,
      businessPropertyPrice,
      businessToolsCoverage,
    } = this.state;

    if (this.state.firstboxChecked === false) {
      this.setState({ generalLiabilityPrice: '$ 0' });
    }
    if (this.state.secondtboxChecked === false) {
      this.setState({ professionalLiabilityPrice: '$ 0' });
    }
    if (this.state.thirdtboxChecked === false) {
      this.setState({ businessToolsCoverage: '$ 0' });
      this.setState({ businessPropertyPrice: '$ 0' });
    }

    let coverages = [];

    if (this.state.checkedFirsRadio) {
      let coverage = {
        coverageName: 'General Liability',
        limit: this.state.generalLiabilityPrice === '$ 2,000,000' ? 2000000 : 1000000,
      };
      coverages.push(coverage);
    }
    if (this.state.thirdtboxChecked) {
      let coverage = {
        coverageName: 'Property',
        limit: this.state.businessPropertyPrice === '$ 25,000' ? 25000 : 10000,
      };
      coverages.push(coverage);
    }
    if (this.state.businessToolsCoverageCheckBox) {
      let coverage = {
        coverageName: 'Tools',
        limit: 10000,
      };
      coverages.push(coverage);
    }
    if (this.state.secondtboxChecked) {
      let coverage = {
        coverageName: 'Professional Liability',
        limit: this.state.professionalLiabilityPrice === '$ 2,000,000' ? 2000000 : 1000000,
      };
      coverages.push(coverage);
    }
    dispatch({
      type: 'UPDATE_COVERAGE',
      payload: coverages,
    });

    setTimeout(() => {
      dispatch({
        type: 'UPDATE_STEP_THREE',
        payload: {
          policyStartDate,
          generalLiabilityPrice,
          professionalLiabilityPrice,
          businessPropertyPrice,
          businessToolsCoverage,
        },
      });
    }, 100);
    setTimeout(() => {
      this.props.navigate('/sbi/loading');
    }, 1000);
  }

  render() {
    const { userOccupation, t } = this.props;
    return (
      <>
        <Header isBackButton={false} />
        <Tab activeTab={3} />
        <NeedHelp />
        <div className='step3'>
          <div className='nexus-row row-cols-1'>
            <div className='nexus-col-lg-12 nexus-col-sm-4 page-title'>{t('step3.selectCoverage')}</div>
            <div className='nexus-col-lg-3 nexus-col-sm-2'>
              <p className='date'>{t('step3.policyStartDate')}</p>
              <small style={{ fontSize: '12px' }}>{t('step3.dateRequired')} </small>
              <NexusInput
                type='date'
                oninput={(event) =>
                  this.setState({
                    policyStartDate: event.target.value,
                  })
                }
              ></NexusInput>
            </div>
          </div>

          <div className='nexus-row mt-5'>
            <div className='nexus-col-lg-10 nexus-col-sm-4'>
              <p className='boxTittle'>{t('step3.selectCheckbox')}</p>
            </div>
          </div>

          {/* First box */}
          <div className='row box'>
            <div className='col-12'>
              <div className='row mt-4'>
                <div className='col-lg-11 col-sm-10 boxHeader'>{t('step3.generalLiability')}</div>
                <div className='col-lg-1 col-sm-2'>
                  <NexusCheckbox onchange={(event) => this.setBackgroundColor(event)}></NexusCheckbox>
                </div>
              </div>
            </div>

            <div
              className='col-12 justify-content-start'
              style={
                !this.state.firstboxChecked ? { backgroundColor: 'rgba(0, 0, 0, 0.17)' } : { backgroundColor: 'white' }
              }
            >
              <div className='row row-cols-1 my-2'>
                <div className='col'>
                  <p className='date'>{t('step3.propertyDamage')}</p>
                </div>
                <div className='col'>
                  <div className='row row-cols-auto'>
                    <div className='col'>
                      <NexusRadio
                        disabled={!this.state.firstboxChecked}
                        // checked={this.state.checkedFirsRadio}
                        name='GeneralLiability'
                        onchange={() => this.setGeneralLiability('generalLiabilityPrice', '$ 1,000,000')}
                      >
                        $ 1,000,000
                      </NexusRadio>
                    </div>
                    <div className='col'>
                      <NexusRadio
                        disabled={!this.state.firstboxChecked}
                        // checked={this.state.checkedFirsRadio}
                        style={this.state.boxChecked ? { color: 'red' } : { color: 'black' }}
                        name='GeneralLiability'
                        onchange={() => this.setGeneralLiability('$ 2,000,000')}
                      >
                        $ 2,000,000
                      </NexusRadio>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row row-cols-2 py-2'>
                <div className='col-12'>
                  <p className='date'>{t('step3.includes')}:</p>
                </div>
                <div className='col-lg-4 col-sm-6'>
                  <p className='date'>{t('step3.products')} </p>
                </div>
                <div className='col'>
                  <p className='date'>$ 1,000,000</p>
                </div>
                <div className='col-lg-4 col-sm-6'>
                  <p className='date'>{t('step3.tenents')}</p>
                </div>
                <div className='col'>
                  <p className='date'>$ 500,000</p>
                </div>
              </div>
            </div>
          </div>

          {/* Second box */}
          {userOccupation === 'Accountant' && (
            <div className='row mt-4 box'>
              <div className='col-12'>
                <div className='row mt-4'>
                  <div className='col-lg-11 col-sm-10 boxHeader'>
                    {t('step3.professionalLiability')}{' '}
                    <small style={{ marginLeft: '10px', fontSize: '12px' }}> {t('step3.applyingAccount')}</small>
                  </div>
                  <div className='col-lg-1 col-sm-2'>
                    <NexusCheckbox onchange={(event) => this.setBackgroundColor2ndBox(event)}></NexusCheckbox>
                  </div>
                </div>
              </div>

              <div
                className='col-12 justify-content-start'
                style={
                  !this.state.secondtboxChecked
                    ? { backgroundColor: 'rgba(0, 0, 0, 0.17)' }
                    : { backgroundColor: 'white' }
                }
              >
                <div className='row row-cols-1 py-3'>
                  {/* <div className="col">
                                    <p className="date">Bodily Injury + Property Damage Liability
                                    </p>
                                </div> */}
                  <div className='col'>
                    <div className='row row-cols-auto'>
                      <div className='col'>
                        <NexusRadio
                          disabled={!this.state.secondtboxChecked}
                          //  checked = {this.state.checkedSecondRadio}
                          name='ProfessionalLiability'
                          onchange={() => this.setProfessionalLiability('$ 1,000,000')}
                        >
                          $ 1,000,000
                        </NexusRadio>
                      </div>
                      <div className='col'>
                        <NexusRadio
                          disabled={!this.state.secondtboxChecked}
                          // checked = {this.state.checkedSecondRadio}
                          name='ProfessionalLiability'
                          onchange={() => this.setProfessionalLiability('$ 2,000,000')}
                        >
                          $ 2,000,000
                        </NexusRadio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Third box */}
          <div className='row mt-4 box'>
            <div className='col-12'>
              <div className='row mt-4'>
                <div className='col-lg-11 col-sm-10 boxHeader'>
                  {t('step3.businessProperty')}{' '}
                  <small style={{ fontSize: '16px', lineHeight: '20px', marginLeft: '10px', color: '#0071B3' }}>
                    {' '}
                    {t('step3.addon')}
                  </small>
                </div>
                <div className='col-lg-1 col-sm-2'>
                  <NexusCheckbox onchange={(event) => this.setBackgroundColor3rdBox(event)}></NexusCheckbox>
                </div>
              </div>
            </div>
            <div
              className='col-12 justify-content-start'
              style={
                !this.state.thirdtboxChecked ? { backgroundColor: 'rgba(0, 0, 0, 0.17)' } : { backgroundColor: 'white' }
              }
            >
              <div className='row row-cols-1 py-2'>
                <div className='col'>
                  <p className='date'>{t('step3.chooseAProperty')}</p>
                </div>
                <div className='col'>
                  <div className='row row-cols-auto'>
                    <div className='col'>
                      <NexusRadio
                        disabled={!this.state.thirdtboxChecked}
                        name='BusinessProperty'
                        onchange={() => this.setbusinessPropertyPrice('$ 10,000')}
                      >
                        $ 10,000
                      </NexusRadio>
                    </div>
                    <div className='col'>
                      <NexusRadio
                        disabled={!this.state.thirdtboxChecked}
                        name='BusinessProperty'
                        onchange={() => this.setbusinessPropertyPrice('$ 25,000')}
                      >
                        $ 25,000
                      </NexusRadio>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row row-cols-2 py-2'>
                <div className='col-12'>
                  <p className='date'>{t('step3.otherAdOns')}</p>
                </div>
                <div className='col-lg-4 col-sm-6'>
                  <p className='date'>{t('step3.businessTool')} </p>
                </div>
                <div className='col'>
                  <p className='date'></p>
                </div>
                <div className='col-lg-4 col-sm-6'>
                  <div className='row'>
                    <div className='col-lg-1 col-sm-1'>
                      <NexusCheckbox
                        disabled={!this.state.thirdtboxChecked}
                        onchange={(event) => this.setbusinessToolsCoverage(event.target.checked)}
                      >
                      </NexusCheckbox>
                    </div>
                    <div className='col-lg-10 col-sm-6'>
                      <strong> $ 10,000 </strong>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className='nexus-row  nexus-mt-4'>
            <div className='nexus-col-sm-1 nexus-col-lg-2'>
              <button onClick={() => this.navigateToPrevPage()} className='nexus-btn'>
                {t('step3.back')}
              </button>
            </div>
            <div className='nexus-col-sm-1 nexus-col-lg-2'>
              <button onClick={() => this.nextPage()} className='nexus-btn-primary'>
                {t('step3.getQoute')}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function AccountedNavigation(props) {
  let navigate = useNavigate();
  return <Accounted {...props} navigate={navigate} />;
}

const mapStateToProps = (state) => {
  const { userInfo } = state;
  return {
    userInfo: userInfo,
    userOccupation: userInfo.userOccupation,
  };
};
const StepThreeWithLang = withTranslation('common')(AccountedNavigation);

export default connect(mapStateToProps)(StepThreeWithLang);
