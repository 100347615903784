import { NexusModal, NexusModalFooter, NexusModalBody, NexusModalHeader } from '@nexus/react';
import './style.scss';
import {useTranslation} from "react-i18next";

const Popup = ({ show, data, onCancel, onAccept }) => {
    const {t} = useTranslation('common');
    let addressLine1= (`${data?.premiseNumber || ' '} ` ) + (` ${data?.thoroughfare || ' '}`);
    let addressLine2 = data?.subBuilding || '';
    let city= data?.locality;
    let state= data?.administrativeArea ;
    let pincode= data?.postalCode;
    let country= data?.countryISO3;
   
    return (
        <NexusModal show={show} fullscreen={false} className="popup" size={'xs'}>
            <NexusModalHeader>{t('common.correctAddress')}</NexusModalHeader>
            <NexusModalBody>
                {data && <div>
                    {/* <div>Olympic Blvd</div>
                    <div>Melbourne, Vic {data.postalCode}</div> */}
                    <div>{addressLine1} {addressLine2}</div>
                    <div>{city}, {state} {pincode}</div>
                </div>}
            </NexusModalBody>
            <NexusModalFooter>
                <button onClick={() => { onCancel() }} className="nexus-btn-primary">No</button>
                <button onClick={() => { onAccept() }} className="nexus-btn">Yes</button>
            </NexusModalFooter>
        </NexusModal>
    );
}

export default Popup;