
class Service {
  API_URL = process.env.REACT_APP_BASE_API_URL;
  API_KEY = process.env.REACT_APP_BASE_API_KEY;
  API_ENV = process.env.REACT_APP_BASE_ENV;

  requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'access-control-allow-header': '*',
      'apikey': this.API_KEY,
    },
    body: '',
  };

  /* AUTH TOKEN API  */
  getAuthToken() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_MISC_TOKEN_CLIENT_ID,
        client_secret: process.env.REACT_APP_MISC_TOKEN_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_MISC_TOKEN_GRANT_TYPE,
        resource: process.env.REACT_APP_MISC_TOKEN_RESOURCE,
      }),
    };

    fetch(
      `https://nexusforinsurance-${this.API_ENV}.sbp.eyclienthub.com/ni/miscellaneous/app/keyvault/token`,
      requestOptions
    )
      .then((res) => res.text())
      .then((data) => {
        localStorage.setItem('token', data);
      })
      .catch((err) => console.log(err));
  }

  addressValidation(data) {
   // this.requestOptions.headers['X-InsurCode'] = 'PRECISELY';
   this.postMethodParams(data);
    return fetch(
      this.API_URL+'/ni/intladdress/validation/app/v1/validateinternationaladdress',
      this.requestOptions
    );
  }

  partyWf(params) {
    this.postMethodParams(params);
    return fetch(
      this.API_URL+`/ni/partywf/app/v1/startcreateparty`,
      this.requestOptions
    );
  }

  createSubmission(data) {
    this.postMethodParams(data);
    return fetch(
      this.API_URL+'/ni/commercialsbisubmission/app/v1/createsubmission',
      this.requestOptions
    );
  }

  getPricing(data) {
    this.postMethodParams(data);
    return fetch(
      this.API_URL+'/ni/commercialsbisubmission/app/v1/getpricingsubmission',
      this.requestOptions
    );
  }

  acceptQuote(data) {
    this.postMethodParams(data);
    return fetch(
      this.API_URL+`/ni/commercialsbisubmission/app/v1/acceptquotesubmission`,
      this.requestOptions
    );
  }

  createPolicy(data) {
    this.postMethodParams(data);
    return fetch(
      this.API_URL+`/ni/commercialsbipolicy/app/v1/createpolicy`,
      this.requestOptions
    );
  }

  getPolicyData(params) {
    this.postMethodParams(params);
    return fetch(
      this.API_URL+`/ni/commercialsbipolicy/app/v1/getpolicy`,
      this.requestOptions
    );
  }

  postMethodParams(params) {
    const token = localStorage.getItem('token');
    this.requestOptions.method = 'POST';
    this.requestOptions.headers.Authorization = `Bearer ${token}`;
    this.requestOptions.body = JSON.stringify(params);
  }

  getMethodParams() {
    this.requestOptions.method = 'GET';
    this.requestOptions.body = null;
    this.requestOptions.headers.Authorization = null;
  }

  putMethodParams(params) {
    this.requestOptions.method = 'PUT';
    this.requestOptions.body = JSON.stringify(params);
  }
}

export default Service;