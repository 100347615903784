import { Component } from 'react';
import { Header, Loader } from '../../sharedComponents';
import '../../style/Spinner.scss';
import { useNavigate } from 'react-router-dom';
import Service from "../../sharedComponents/Service";
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
class PaymentSpinner extends Component {

  service = new Service();

  componentDidMount() {
    // localStorage.setItem("token", 'remove-token');
    this.tokenAPI();
  }

  tokenAPI() {
    this.service.getAuthToken();
    this.acceptQoute();
  };


  acceptQoute = () => {
    let params = {
      "submissionId": this.props.submission.submissionId
    }
    this.service
      .acceptQuote(params)
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          data = JSON.parse(data);
          this.createPolicy(data.data);
          console.log(data)
        }
      })
      .catch((err) => console.log(err));
  }

  createPolicy = (data) => {
    const { dispatch,t } = this.props;
    const { stepThree, basicInformation, businessAddress, coverages,stepTwo } = this.props.userInfo;
    let params = {
      "lob": "Commercial Small Business Insurance",
      "policyEffectiveDate": stepThree.policyStartDate + 'T00:00:00Z',
      "submissionId": data.submissionId,
      "paymentStatus": "PAID",
      "businessOwnership": "INDIVIDUAL",
      "businessName": "Nexus for Insurance",
      "fein": "0",
      "revenue": 0,
      "fullTimeEmployees": 0,
      "partTimeEmployees": 0,
      "occupations": [
        {
          "primaryInsured": true,
          "firstName": basicInformation.firstName,
          "lastName": basicInformation.secondName,
          "occupations": [
            basicInformation.occuupation.toUpperCase()
          ],
          "mountInstallationServices": stepTwo.applianceMounting,
          "qualified": stepTwo.qualifiedCPA,
          "address1": businessAddress.streetAddress,
          "address2": businessAddress.apartment ? businessAddress.apartment : ' ',
          "locality": businessAddress.city,
          "administrativeArea": businessAddress.province,
          "postalCode": businessAddress.postalCode,
          "country": "CA",
          "coverages": coverages,
        }
      ],
      "policyHolderDetails": {
        "firstName": basicInformation.firstName,
        "lastName": basicInformation.secondName,
        "dateOfBirth": "1985-12-20T00:00:00Z",
        "gender": "MALE",
        "address": {
          "address1": businessAddress.streetAddress,
          "address2": businessAddress.apartment ? businessAddress.apartment: ' ',
          "locality": businessAddress.city,
          "administrativeArea": businessAddress.province,
          "postalCode": businessAddress.postalCode,
          "country": "CA"
        },
        "addressType": "RESIDENCE",
        "email": 'nexus4insurance@ey.com',
        "phoneNumber": "9999999999"
      }
    }


    this.service
      .createPolicy(params)
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          data = JSON.parse(data);
          dispatch({
            type: 'UPDATE_POLICY', payload: data.data
          });
          // this.navigateToNextPage();
          this.loader.loadingCompleted();
        }
      })
      .catch((err) => console.log(err));
  }

  doneLoading = () => {
    this.props.navigate('/sbi/success')
  }

  render() {
    const {t} = this.props;
    return (
      <>
        <Header isBackButton={false} />
        <div className='spinner-page'>
          <Loader
            ref={(instance) => {
              this.loader = instance;
            }}
            title={t('loader.paymentProcessing')}
            doneLoading={() => this.doneLoading()}
          />
        </div>
      </>
    );
  }
}


function PaymentSpinnerrNavigate(props) {
  let navigate = useNavigate();
  return <PaymentSpinner {...props} navigate={navigate} />;
}


const mapStateToProps = (state) => {
  const { userInfo } = state;
  return {
    userInfo: userInfo,
    pricing: userInfo.pricing,
    submission: userInfo.submission
  };
};
const SpinnerWithLang = withTranslation('common')(PaymentSpinnerrNavigate);
export default connect(mapStateToProps)(SpinnerWithLang);

