import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Header } from '../../sharedComponents';
import '../../style/Auth.scss';
import { NexusFormField, NexusInput, NexusLabel } from '@nexus/react';
import { useNavigate } from 'react-router-dom';
import {CooperatorLogo} from '../../../assets/icons';
import { authProvider } from '../../azureAuth/authConfig';
import { connect } from 'react-redux';
import Service from '../../sharedComponents/Service';
import {withTranslation} from "react-i18next";
class Login extends Component {
  state = {
    userName: '',
  };
  service = new Service();

  componentDidMount() {
    const { dispatch,userName } = this.props;
    localStorage.setItem("token", 'remove-token');
    if(userName){
      this.setState({userName:userName});
    }
    this.tokenAPI();
    // dispatch({ type: 'RESET_ALL_USER_INFO_STATES', payload: {} });
  }

  tokenAPI() {
    this.service.getAuthToken();
  };

  setValue = (name, val) => {
    this.setState({
      [name]: val,
    });
  };

  login(path) {
    this.azureAuthProcess();
    // this.props.navigate(path);
  }
  navigateToNextPage(path) {
    this.props.navigate(path);
  }
    azureAuthProcess() {
    const userInfoFromAzure = authProvider.getAccountInfo();
    console.log(this.props)
    console.log(userInfoFromAzure.account.name);
    console.log(userInfoFromAzure);
    this.props.dispatch({type : 'USERNAME', 
    payload : userInfoFromAzure.account.name})
    this.props.navigate('/sbi/customer-portal');
    // console.log(userInfoFromAzure.jwtIdToken);
    // localStorage.setItem('token', userInfoFromAzure.jwtIdToken);
    // localStorage.setItem('userName', userInfoFromAzure.account.accountIdentifier);
  }

  render() {
    const { userName } = this.state;
    const { t } = this.props;
    return (
      <>
        <Header hideLogo={true} isBackButton={false} />
        <div className='login-background'>
          <div className='auth-container'>
            <div className='nexus-row'>
              <div className='nexus-col-xs-4'>
                <img className='logo' onClick={() => this.navigateToNextPage('/')} src={CooperatorLogo} alt='' />
              </div>
            </div>
            <div className='nexus-row input-container'>
              <div className='nexus-col-xs-4 welcome-text nexus-mt-5'>{t('auth.login')}</div>
              <div className='nexus-col-xs-4 details nexus-mt-2'>{t('auth.welcomeBack')}</div>
              <div className='nexus-col-xs-4 nexus-mt-3'>
                <NexusFormField className='input-field'>
                  <NexusLabel>{t('auth.username')}</NexusLabel>
                  <NexusInput
                    value={userName}
                    oninput={(event) => this.setValue('userName', event.target.value)}
                  ></NexusInput>
                </NexusFormField>
              </div>
              <div className='nexus-col-xs-4 nexus-mt-4'>
                <button onClick={() => this.login('/sbi/customer-portal')} className='nexus-btn-primary'>
                {t('auth.login')}
                </button>
              </div>
              <div className='nexus-col-xs-4 nexus-mt-3 details'>
               {t('auth.notRegisterd')}{' '}
                <NavLink className='colored-details' to={`/sbi/sign-up`} key='/sbi/sign-up'>
                {t('auth.createAnAccount')}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function LoginNavigate(props) {
  let navigate = useNavigate();
  return <Login {...props} navigate={navigate} />;
}


const mapStateToProps = (state) => {
  const { PolicyInfo } = state;
  return {
    userName: PolicyInfo.userName,
  };
};
const LoginWithLang = withTranslation('common')(LoginNavigate);
export default connect(mapStateToProps)(LoginWithLang);

