import { Component } from 'react';
import { Header, Popup, Tab, Radio, NeedHelp } from '../../sharedComponents';
import '../../style/StepOneStyle.scss';
import { NexusFormField, NexusInput, NexusLabel, NexusSelect } from '@nexus/react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Service from '../../sharedComponents/Service';
import { withTranslation } from 'react-i18next';
class Stepone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      secondName: '',
      email: '',
      occuupation: '',
      streetAddress: '',
      apartment: '',
      city: '',
      province: '',
      postalCode: '',
      isOccupationCorrect: '',
      addressPopup: false,
      addressResponse: null,
    };
  }

  service = new Service();

  componentDidMount() {
    this.tokenAPI();
    const { basicInformation, businessAddress } = this.props;
    if (basicInformation) {
      const { firstName, secondName, email, occuupation, isOccupationCorrect } = basicInformation;
      this.setState({ firstName, secondName, email, occuupation, isOccupationCorrect });
    }
    if (businessAddress) {
      const { streetAddress, apartment, city, province, postalCode } = businessAddress;
      this.setState({ streetAddress, apartment, city, province, postalCode });
    }
  }

  tokenAPI() {
    this.service.getAuthToken();
  }

  validateAddress = () => {
    const { streetAddress, apartment, city, province, postalCode } = this.state;

    let params = {
      address1: streetAddress,
      address2: apartment ? apartment : '',
      administrativeArea: province,
      country: 'CA',
      locality: city,
      postalCode: postalCode,
    };

    this.service
      .addressValidation(params)
      .then((res) => res.text())
      .then((data) => {
        data = JSON.parse(data);        
        this.callPartyWfApi(data?.data[0]?.addressComponents);
      })
      .catch((err) => console.log(err));
  };

   callPartyWfApi(addressComponents) {
    const { dispatch } = this.props;
    const { firstName, secondName, streetAddress, apartment, city, province, postalCode, email } = this.state;
    const resbody = {
      partyType: 'PROSPECT',
      firstName,
      middleName: firstName,
      lastName: secondName,
      dob: '1985-12-20T00:00:00Z', // Discussed Hard coded 
      driverLicenseExpiry: '2028-08-27T00:00:00Z',
      driverLicenseNumber: '899911052',
      ssn: '424064440',
      partyContactDetails: [
        {
          address: {
            addressLine1: streetAddress,
            addressLine2: apartment,
            city,
            zip: postalCode,
            state: province,
            country: 'CA', // Discussed Hard coded 
            addressType: 'RESIDENTIAL',
            prefixDirection: 'NORTH',
            postfixDirection: 'NORTH',
            houseNumber: '231',
            streetName: streetAddress,
            streetType: 'Dr',
            unitType: 'Unit type',
            unitNumber: 'Unit Number',
          },
          contactType: 'PRIMARY',
          phoneNumber: '9999999999', // Discussed Hard coded 
          emailAddress: email,
          companyName: 'Nexus',
          companyCode: 'EY',
        },
      ],
      organizationName: 'Nexus',
      status: 'ACTIVE',
      gender: 'Male', // Discussed Hard coded 
      submission: null,
    };
    this.service.partyWf(resbody).then((res) => res.text()).then((data) => {
      data = JSON.parse(data);
      dispatch({
        type: 'PARTYID',
        payload: data?.createParty?.data.partyId || data?.getParty?.data.partyId,
      });
      this.setState({
        addressPopup: true,
        addressResponse: addressComponents,
      });
    }).catch((err) => console.log(err));
  };

  setValue = (name, val) => {
    this.setState({
      [name]: val.trim(),
    });
  };
  setDropdownValue = (name, val) => {
    this.setState({
      [name]: val.trim(),
    });
  };

  navigateToNextPage() {
    this.validateAddress();
  }

  onAcceptAddress() {
    this.setState({
      addressPopup: false,
    });
    const {
      firstName,
      secondName,
      email,
      occuupation,
      streetAddress,
      apartment,
      city,
      province,
      postalCode,
      isOccupationCorrect,
    } = this.state;
    const { dispatch } = this.props;
    dispatch({
      type: 'UPDATE_USER_OCCUPATION',
      payload: occuupation,
    });
    dispatch({
      type: 'UPDATE_BASIC_INFO',
      payload: {
        firstName,
        secondName,
        email,
        occuupation,
        isOccupationCorrect,
      },
    });
    dispatch({
      type: 'UPDATE_BUSINESS_ADDRESS',
      payload: {
        streetAddress,
        apartment,
        city,
        province,
        postalCode,
      },
    });

    if (occuupation === 'Accountant') {
      this.props.navigate('/sbi/step-two-accountant');
    }
    if (occuupation === 'Electrician') {
      this.props.navigate('/sbi/step-two-electrition');
    }
    if (occuupation === 'Plumber') {
      this.props.navigate('/sbi/step-two-plumber');
    }
  }

  onCancelAddress = () => {
    this.setState({
      addressPopup: false,
    });
  };

  render() {
    const {
      firstName,
      secondName,
      email,
      occuupation,
      streetAddress,
      apartment,
      city,
      province,
      postalCode,
      addressPopup,
      addressResponse,
      isOccupationCorrect,
    } = this.state;
    const { t } = this.props;
    const provinceName = [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Nova Scotia',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
    ];
    return (
      <>
        <Header isBackButton={false} />
        <Tab activeTab={1} />
        <NeedHelp />
        <Popup
          show={addressPopup}
          data={addressResponse}
          onCancel={() => this.onCancelAddress()}
          onAccept={() => this.onAcceptAddress()}
        />
        <div>
          <div className='nexus-row step-one step-one-container'>
            <div className='nexus-col-xs-4'>
              <div className='page-title'>{t('step1.getYourQoute')}</div>
            </div>
            <div className='nexus-col-xs-4 nexus-mt-2'>
              <div className='page-sub-title'>{t('step1.basicInfo')}</div>
            </div>
            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.firstName')}</NexusLabel>
                <NexusInput
                  value={firstName}
                  oninput={(event) => this.setValue('firstName', event.target.value)}
                ></NexusInput>
              </NexusFormField>
            </div>
            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.lastName')}</NexusLabel>
                <NexusInput
                  value={secondName}
                  oninput={(event) => this.setValue('secondName', event.target.value)}
                ></NexusInput>
              </NexusFormField>
            </div>
            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.email')}</NexusLabel>
                <NexusInput value={email} oninput={(event) => this.setValue('email', event.target.value)}></NexusInput>
              </NexusFormField>
            </div>
            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.selectYourOccupation')}</NexusLabel>
                <NexusSelect
                  value={occuupation}
                  onchange={(event) => this.setDropdownValue('occuupation', event.target.value)}
                >
                  <option disabled>{t('step1.selectOccupation')}</option>
                  <option className='select-option' value='Accountant'>
                    {t('step1.accountant')}
                  </option>
                  <option className='select-option' value='Electrician'>
                    {t('step1.electrician')}
                  </option>
                  <option className='select-option' value='Plumber'>
                    {t('step1.plumber')}
                  </option>
                </NexusSelect>
              </NexusFormField>
            </div>
            {occuupation && (
              <>
                <div className='nexus-col-xs-4 page-sub-title'>{t('step1.confirmDescription')}</div>
                <div className='nexus-col-xs-4 radio-container'>
                  <Radio
                    value={true}
                    label={t('common.yes')}
                    name='isOccupationCorrect'
                    onChange={(event) => {
                      console.log('selected:', event.target.value);
                      this.setState({ isOccupationCorrect: event.target.value });
                    }}
                  />
                  <Radio
                    value={false}
                    label={t('common.no')}
                    name='isOccupationCorrect'
                    onChange={(event) => {
                      console.log('selected:', event.target.value);
                      this.setState({ isOccupationCorrect: event.target.value });
                    }}
                  />
                </div>
              </>
            )}
            {occuupation === 'Accountant' && (
              <div className='nexus-col-xs-4 message-container nexus-ml-1'>{t('step1.preparingAccount')}</div>
            )}
            {occuupation === 'Electrician' && (
              <div className='nexus-col-xs-4 message-container'>{t('step1.installAndRepair')}</div>
            )}
            {occuupation === 'Plumber' && (
              <div className='nexus-col-xs-4 message-container'>{t('step1.UnderSupervision')}</div>
            )}

            <div className='nexus-col-xs-4 nexus-mt-4'>
              <div className='page-sub-title'>{t('step1.businessAddress')}</div>
            </div>
            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.streetAddress')}</NexusLabel>
                <NexusInput
                  value={streetAddress}
                  oninput={(event) => this.setValue('streetAddress', event.target.value)}
                ></NexusInput>
              </NexusFormField>
            </div>
            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.aprtment')}</NexusLabel>
                <NexusInput
                  value={apartment}
                  oninput={(event) => this.setValue('apartment', event.target.value)}
                ></NexusInput>
              </NexusFormField>
            </div>
            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.city')}</NexusLabel>
                <NexusInput value={city} oninput={(event) => this.setValue('city', event.target.value)}></NexusInput>
              </NexusFormField>
            </div>

            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.selectProvince')}</NexusLabel>
                <NexusSelect
                  value={province}
                  onchange={(event) => this.setDropdownValue('province', event.target.value)}
                >
                  <option disabled>{t('step1.province')}</option>
                  {provinceName &&
                    provinceName.map((itemName) => (
                      <option className='select-option' value={itemName}>
                        {itemName}
                        {/* {t(`step1.${itemName}`)} */}
                        {/* {t(`common.step${index+1}`)} */}
                      </option>
                    ))}
                </NexusSelect>
              </NexusFormField>
            </div>

            <div className='nexus-col-xs-4 nexus-col-lg-6'>
              <NexusFormField>
                <NexusLabel>{t('step1.postalCode')}</NexusLabel>
                <NexusInput
                  value={postalCode}
                  oninput={(event) => this.setValue('postalCode', event.target.value)}
                ></NexusInput>
              </NexusFormField>
            </div>
            <div className='nexus-col-xs-4 nexus-mt-4'>
              <button
                disabled={
                  !firstName ||
                  !secondName ||
                  !email ||
                  !occuupation ||
                  !streetAddress ||
                  !city ||
                  !province ||
                  !postalCode ||
                  isOccupationCorrect !== 'true'
                }
                onClick={() => this.navigateToNextPage()}
                className='nexus-btn-primary'
              >
                {t('common.next')}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function StepOneNavigation(props) {
  let navigate = useNavigate();
  return <Stepone {...props} navigate={navigate} />;
}

const mapStateToProps = (state) => {
  const { userInfo } = state;
  return {
    basicInformation: userInfo.basicInformation,
    businessAddress: userInfo.businessAddress,
  };
};

const StepOneWithLang = withTranslation('common')(StepOneNavigation);

export default connect(mapStateToProps)(StepOneWithLang);