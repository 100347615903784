
const initialState = {
    policyNumber:'',
    userName:'',
  };
  
  
  const setPolicyNumber = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      policyNumber: payload,
    };
  };

  const setUserName = (state = initialState, action) => {
    const { payload } = action;
    return {
      ...state,
      userName: payload,
    };
  };
  
  const PolicyInfo = (state = initialState, action) => {
    switch (action.type) {
     case 'SET_POLICY_NUMBER':
          return setPolicyNumber(state, action);
    case 'SET_USER_NAME':
      return setUserName(state, action);
      default:
        return state;
    }
  };
  
  export default PolicyInfo;
  