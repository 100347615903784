import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Header } from '../../sharedComponents';
import '../../style/Auth.scss';
import { NexusFormField, NexusInput, NexusLabel } from '@nexus/react';
import { useNavigate } from 'react-router-dom';
import { CooperatorLogo } from '../../../assets/icons';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
class CreateAccount extends Component {
  state = {
    userName: '',
    policyNumber: '',
  };

  setValue = (name, val) => {
    this.setState({
      [name]: val,
    });
  };

  createAccount() {
    const {policyNumber,userName} = this.state;
    this.props.dispatch({type: 'SET_USER_NAME', payload: userName});
    this.props.dispatch({type: 'SET_POLICY_NUMBER', payload: policyNumber});
    this.props.navigate('/sbi/login');
  }

  navigateToNextPage(path) {
    this.props.navigate(path);
  }

  render() {
    const { userName, policyNumber } = this.state;
    const { t } = this.props;
    return (
      <>
        <Header hideLogo={true} isBackButton={false} />
        <div className='login-background'>
          <div className='auth-container'>
            <div className='nexus-row'>
              <div className='nexus-col-xs-4'>
                <img className='logo' onClick={() => this.navigateToNextPage('/')} src={CooperatorLogo} alt='' />
              </div>
            </div>
            <div className='nexus-row input-container'>
              <div className='nexus-col-xs-4 welcome-text nexus-mt-5'>{t('auth.createYourAccount')}</div>
              <div className='nexus-col-xs-4 details nexus-mt-2'>{t('auth.createInstantly')}</div>
              <div className='nexus-col-xs-4 nexus-mt-3'>
                <NexusFormField className='input-field'>
                  <NexusLabel>{t('auth.username')}</NexusLabel>
                  <NexusInput
                    value={userName}
                    oninput={(event) => this.setValue('userName', event.target.value)}
                  ></NexusInput>
                </NexusFormField>
              </div>
              <div className='nexus-col-xs-4'>
                <NexusFormField className='input-field'>
                  <NexusLabel>{t('auth.policyNumber')}</NexusLabel>
                  <NexusInput
                    value={policyNumber}
                    oninput={(event) => this.setValue('policyNumber', event.target.value)}
                  ></NexusInput>
                </NexusFormField>
              </div>
              <div className='nexus-col-xs-4 nexus-mt-4'>
                <button
                  style={{ width: '200px' }}
                  onClick={() => this.createAccount()}
                  className='nexus-btn-primary'
                >
                  {t('auth.createAccount')}
                </button>
              </div>
              {/* <div className='nexus-col-xs-4 nexus-mt-3 details'>
                {t('auth.notRegisterd')}{' '}
                <NavLink className='colored-details' to={`/sbi/login`} key='/sbi/sign-up'>
                {t('auth.signIn')}
                </NavLink>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
function CreateAccountNavigate(props) {
  let navigate = useNavigate();
  return <CreateAccount {...props} navigate={navigate} />;
}
const CreateWithLang = withTranslation('common')(CreateAccountNavigate);
export default connect()(CreateWithLang);

