import React, { useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
  let navigate = useNavigate();
  useEffect(() => {
    navigate('/sbi');
  });


  return (
    <div>
      LandingPage
    </div>
  );
}

export default LandingPage;