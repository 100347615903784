import { useState, useImperativeHandle, forwardRef } from "react";
import './Loader.scss';
import ProgressCircle from './ProgressCircle';

const Loader = forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(true);
  
    useImperativeHandle(ref, () => ({
      loadingCompleted() {
        setIsLoading(false);
      },
    }));

    const { title, subtitle } = props;
    return (
        <div className="spinner-content">
            <div className="building-quote">{title}</div>
            <ProgressCircle doneLoading={()=> props.doneLoading()} isLoading={isLoading} />
            <div className="sub-text">{subtitle}</div>
        </div>
    );
});


export default Loader;