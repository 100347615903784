import { Component } from "react";
import { Header, Loader } from '../../sharedComponents';
import '../../style/Spinner.scss';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Service from "../../sharedComponents/Service";
import {withTranslation} from "react-i18next";
class Spinner extends Component {
    service = new Service();

    componentDidMount() {
        this.loader.loadingCompleted();
        // localStorage.setItem("token", 'remove-token');
        this.tokenAPI();
    }

    tokenAPI() {
        this.service.getAuthToken();
        this.createSubmission();
    };

    createSubmission = () => {
        const {basicInformation,businessAddress,stepTwo,coverages,stepThree, partyId} = this.props.userInfo;
        const { dispatch,t } = this.props;
        console.log(coverages)
        let params = {
            "lob": "Commercial Small Business Insurance",
            "policyEffectiveDate": stepThree.policyStartDate+'T00:00:00Z', 
            "submissionStatus": "DRAFT",
            "paymentFrequency": "monthly",
            "businessOwnership": "INDIVIDUAL",
            "businessName": "Nexus for Insurance",
            "fein": "0",
            "revenue": 0,
            "fullTimeEmployees": 0,
            "partTimeEmployees": 0,
            "occupations": [
                {
                    "primaryInsured": true,
                    "firstName": basicInformation.firstName, 
                    "lastName": basicInformation.secondName,  
                    "occupations": [
                        basicInformation.occuupation.toUpperCase()         
                    ],
                    "mountInstallationServices": stepTwo.applianceMounting, 
                    "qualified": stepTwo.qualifiedCPA,                  
                    "address1": businessAddress.streetAddress,  
                    "address2": businessAddress.apartment ? businessAddress.apartment : ' ',		 
                    "locality": businessAddress.city,		
                    "administrativeArea": businessAddress.province,  
                    "postalCode": businessAddress.postalCode,
                    "country": "CA",
                    "coverages": coverages,
                }
            ],
            "policyHolderDetails": {
                "firstName": basicInformation.firstName, 
                "lastName": basicInformation.secondName, 
                "dateOfBirth": "1985-12-20T00:00:00Z",
                "address": {
                    "address1": businessAddress.streetAddress,          
                    "address2": businessAddress.apartment ? businessAddress.apartment : ' ',
                    "locality": businessAddress.city,                      
                    "administrativeArea": businessAddress.province,   
                    "postalCode": businessAddress.postalCode,                
                    "country": "CA"
                },
                "addressType": "RESIDENCE",
                "email": basicInformation.email, // 'nexus4insurance@ey.com',   // Take from UI input                   
                "phoneNumber": "9999999999",
                "gender":"MALE",
                'policyHolderId': partyId,  // 'e6055e62-00cf-478f-8da1-a94139766c82',
            }
        }
        this.service
            .createSubmission(params)
            .then((res) => res.text())
            .then((data) => {
                if(data){
                    data = JSON.parse(data);
                    dispatch({
                        type: 'UPDATE_SUBMISSION', payload: data.data
                    });
                    this.setState({submissionId: data.data.submissionId },()=>{
                        this.getPricing();
                    });
                }

            })
            .catch((err) => console.log(err));
    }

    getPricing = () => {
        const {dispatch} = this.props;
        let params = {
            "submissionId": this.state.submissionId    
        }
        
        this.service
        .getPricing(params)
        .then((res) => res.text())
        .then((data) => {
            if(data){
                data = JSON.parse(data);
                dispatch({
                    type: 'UPDATE_PRICING', payload: data.data
                });
                this.loader.loadingCompleted();
            }
        })
        .catch((err) => console.log(err));
        
    }


    render() {
        const {t} = this.props;
        return (
            <>
                <Header isBackButton={false} />
                <div className="spinner-page">
                    <Loader
                        ref={(instance) => {
                            this.loader = instance;
                        }}
                        title={t('loader.buildingYourQoute')}
                        doneLoading={() => this.props.navigate('/sbi/step-four')}
                        subtitle={t('loader.bePatient')}
                    />
                </div>
            </>
        );
    }
};

function SpinnerrNavigate(props) {
    let navigate = useNavigate();
    return <Spinner {...props} navigate={navigate} />;
}

const mapStateToProps = (state) => {
    const { userInfo } = state;
    return {
        userInfo: userInfo,
        pricing: userInfo.pricing,
        submission: userInfo.submission
    };
};

const SpinnerWithLang = withTranslation('common')(SpinnerrNavigate);
export default connect(mapStateToProps)(SpinnerWithLang);