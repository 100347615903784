import { Component } from 'react';
import { Header, Button } from '../../sharedComponents';
import '../../style/CustomerPortal.scss';
import { Avatar } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Service from '../../sharedComponents/Service';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  NexusRadio,
  NexusCheckbox,
  NexusModal,
  NexusModalFooter,
  NexusModalBody,
  NexusModalHeader,
  NexusModalHeaderSubtitle,
} from '@nexus/react';

const service = new Service();
class CustomerPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: '',
      policyNumber: '',
      policyEffectiveDate: '',
      occupations: '',
      grossPremium: '',
      grossPremiumCurrency: '',
      generalLiability: {},
      professionalLiability: {},
      propertyLiability: {},
      toolsLiability: {},
      policyHolderDetails: {},
      documents: [],
      showModal: false,
      discountApplied: false,
    };
  }

  navigateToNextPage(path) {
    this.props.navigate(path);
  }
  navigateToNextWebsite(path) {
    window.open(path, '_blank');
  }
  componentDidMount() {
    this.fetchPolicyData(this.props.policyNumber);
  }
  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.policyNumber !== prevProps.policyNumber) {
      this.fetchPolicyData(this.props.policyNumber);
    }
  }
  fetchPolicyData() {
    const { policyNumber } = this.props;
    service
      .getPolicyData({
        policyNumber: policyNumber,
      })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          loader: false,
        });
        this.mapPolicyResponse(data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        this.setState({
          loader: false,
        });
      });
  }

  mapPolicyResponse(policyResponse) {
    // console.log('documents', policyResponse.documents);
    let coverage = policyResponse.occupations[0].coverages;
    let generalLiability = {};
    let professionalLiability = {};
    let propertyLiability = {};
    let toolsLiability = {};
    if (coverage) {
      coverage.forEach((element) => {
        if (element.coverageName === 'General Liability') {
          generalLiability = {
            coverageName: element.coverageName,
            limit: element.limit,
          };
        } else if (element.coverageName === 'Professional Liability') {
          professionalLiability = {
            coverageName: element.coverageName,
            limit: element.limit,
          };
        } else if (element.coverageName === 'Property') {
          propertyLiability = {
            coverageName: element.coverageName,
            limit: element.limit,
          };
        } else if (element.coverageName === 'Tools') {
          toolsLiability = {
            coverageName: element.coverageName,
            limit: element.limit,
          };
        }
      });
    }
    this.setState({
      policyNumber: policyResponse.policyNumber,
      policyEffectiveDate: policyResponse.policyEffectiveDate,
      occupations: policyResponse.occupations,
      grossPremium: policyResponse.grossPremium,
      grossPremiumCurrency: policyResponse.grossPremiumCurrency,
      policyHolderDetails: policyResponse.policyHolderDetails,
      generalLiability,
      professionalLiability,
      propertyLiability,
      toolsLiability,
      documents: policyResponse.documents,
    });
  }
  camelize(str) {
    let newStr = str.toLowerCase();
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  }

  getDownload(docName) {
    if (this.state.documents != null) {
      this.state.documents.map((listOfDoc) => {
        if (listOfDoc.document === docName) {
          this.navigateToNextWebsite(listOfDoc.url);
        }
      });
    } else {
      console.log('this.state', this.state.documents);
    }
  }
  setShow(val) {
    console.log('val', val);
    this.setState({
      showModal: val,
      discountApplied: true
    });
  }

  render() {
    const { t, userName } = this.props;
    const {
      policyNumber,
      policyEffectiveDate,
      occupations,
      grossPremium,
      grossPremiumCurrency,
      generalLiability,
      professionalLiability,
      propertyLiability,
      toolsLiability,
      policyHolderDetails,
      showModal,
      discountApplied,
    } = this.state;
    //console.log('this.state', this.state.documents);
    const currentDate = moment().format('MMM DD, YYYY');
    console.log('this.state', currentDate);
    return (
      <>
        <Header isBackButton={false} profile={true} />
        <div className='customer-portal-page nexus-row'>
          <div className='nexus-col-xs-3'>
            <div className='nexus-row nexus-middle-xs'>
              <img src={Avatar} className='profile-pic nexus-mr-1' alt='avatar' />
              <div className='nexus-ml-2'>
                <div className='title'>
                  {t('customerPortal.hey')} {userName},
                </div>
                <div className='title'>{t('customerPortal.welcomeBack')}</div>
              </div>
            </div>
            <div className='nexus-row nexus-mt-5'>
              <div className='nexus-col-xs-2'>
                <div className='title nexus-mb-2'>{t('customerPortal.policyInfo')}</div>
                <div className='info-box policy-info'>
                  <div className='nexus-row'>
                    <div className='row-title nexus-col-xs-2'>{t('customerPortal.policyNumber')}</div>
                    <div className='row-content nexus-col-xs-2'>{policyNumber}</div>
                  </div>
                  <div className='nexus-row'>
                    <div className='row-title nexus-col-xs-2'>{t('customerPortal.policyPremium')}</div>
                    <div className='row-content nexus-col-xs-2'>
                      {grossPremiumCurrency === 'CAD' ? '$' : ''} {discountApplied ? 0 : Math.round(grossPremium / 12).toFixed(2)}
                    </div>
                  </div>
                  <div className='nexus-row'>
                    <div className='row-title nexus-col-xs-2'>{t('customerPortal.occupation')}</div>
                    <div className='row-content nexus-col-xs-2'>
                      {occupations && occupations[0] ? t(`home.${occupations[0].occupations[0].toLowerCase()}`) : ''}
                    </div>
                  </div>
                  <div className='nexus-row'>
                    <div className='row-title nexus-col-xs-2'>{t('customerPortal.status')}</div>
                    <div className='row-content status-text nexus-col-xs-2'>{discountApplied ?t('customerPortal.basicCoverage') : t('customerPortal.fullCoverage')}{}</div>
                  </div>
                  <div className='nexus-row'>
                    <div className='row-title nexus-col-xs-2'>{t('customerPortal.policyStartDate')}</div>
                    <div className='row-content nexus-col-xs-2'>
                      {policyEffectiveDate && moment(policyEffectiveDate).format('MMM DD, YYYY')}
                    </div>
                    {/* <div className='row-content nexus-col-xs-2'>August 22, 2021</div> */}
                  </div>
                  <div className='nexus-row italic-text'>
                    <div className='nexus-col-xs-4'>{t('customerPortal.renew')}</div>
                  </div>
                </div>
                <div className='left-buttons nexus-pt-4'>
                  <div className='nexus-mb-2'>
                    <Button
                      type='primary'
                      onPress={() => {
                        this.navigateToNextWebsite('https://us-east-1.360sv.com/as/mJZNDrWhmdmLpWRzXrRSp4XP');
                      }}
                    >
                      {t('customerPortal.fileAClaim')}
                    </Button>
                  </div>
                  <div className='nexus-mb-2'>
                    <Button
                      type='primary'
                      onPress={() => {
                        this.getDownload('Policy Schedule');
                      }}
                    >
                      {t('customerPortal.generateCertificate')}
                    </Button>
                  </div>
                  <div className='nexus-mb-2'>
                    <Button type='primary' onPress={() => {}}>
                      {t('customerPortal.addAdditional')}
                    </Button>
                  </div>
                  <div className='nexus-mb-2'>
                    <Button type='primary' onPress={() => {}}>
                      {t('customerPortal.reviewExclusion')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className='nexus-col-xs-2'>
                <div className='title nexus-mb-2'> {t('customerPortal.coverageInfo')}</div>
                {(generalLiability.coverageName ||
                  professionalLiability.coverageName ||
                  toolsLiability.coverageName ||
                  toolsLiability.coverageName) && (
                  <div className='info-box'>
                    {generalLiability && generalLiability.coverageName === 'General Liability' && (
                      <>
                        <div className='nexus-row coverage-tile'> {t('customerPortal.generalLiability')}</div>
                        <div className='nexus-row row-title'>{t('customerPortal.bodilyInjury')}</div>
                        <div className='nexus-row'>
                          <NexusRadio disabled={true} name='DamageLiabality' onchange={() => {}} checked={true}>
                            {/* $ {generalLiability.limit} */}${' '}
                            {new Intl.NumberFormat('en-US').format(generalLiability.limit)}
                          </NexusRadio>
                        </div>
                        <div className='nexus-row row-title'>{t('customerPortal.includes')}</div>
                        <div className='nexus-row'>
                          <div className='nexus-col-lg-8 nexus-col-xs-3 padding-zero'>
                            {t('customerPortal.productCompleted')}
                          </div>
                          <div className='nexus-col-lg-4 nexus-col-xs-1 padding-zero'>$ 1,000,000</div>
                        </div>
                        <div className='nexus-row'>
                          <div className='nexus-col-lg-8 nexus-col-xs-3 padding-zero'>
                            {t('customerPortal.legalLiability')}
                          </div>
                          <div className='nexus-col-lg-4 nexus-col-xs-1 padding-zero'>$ 500,000</div>
                        </div>
                      </>
                    )}

                    {professionalLiability && professionalLiability.coverageName === 'Professional Liability' && (
                      <>
                        <hr />
                        <div className='nexus-mb-3 nexus-my-4'>
                          <div className='nexus-row coverage-tile'>{t('customerPortal.professionalLiability')}</div>
                          <div className='nexus-row'>
                            <NexusRadio disabled={true} name='ProfessionalLiability' onchange={() => {}} checked={true}>
                              {/* $ {professionalLiability.limit} */}${' '}
                              {new Intl.NumberFormat('en-US').format(professionalLiability.limit)}
                            </NexusRadio>
                          </div>
                        </div>
                      </>
                    )}

                    {propertyLiability && propertyLiability.coverageName === 'Property' && (
                      <>
                        <hr />
                        <div className='nexus-mb-3 nexus-my-4'>
                          <div className='nexus-row coverage-tile'>{t('customerPortal.businessProperty')}</div>
                          <div className='nexus-row'>
                            <NexusRadio disabled={true} name='PropertyLiability' onchange={() => {}} checked={true}>
                              {/* $ {propertyLiability.limit} */}${' '}
                              {new Intl.NumberFormat('en-US').format(propertyLiability.limit)}
                            </NexusRadio>
                          </div>
                        </div>
                      </>
                    )}
                    {toolsLiability && toolsLiability.coverageName === 'Tools' && (
                      <>
                        <hr />
                        <div className='nexus-mb-3 nexus-mt-4'>
                          <div className='nexus-row coverage-tile'> {t('customerPortal.tools')}</div>
                          <div className='nexus-row'>
                            <NexusCheckbox disabled={true} checked={true} onchange={() => {}}>
                              {/* $ {toolsLiability.limit} */}${' '}
                              {new Intl.NumberFormat('en-US').format(toolsLiability.limit)}
                            </NexusCheckbox>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='nexus-col-xs-1  nexus-p-2'>
            <div className='nexus-mb-5'>
              <div className='title'> {t('customerPortal.accountDashboard')}</div>
              <div className='date-text'>{currentDate}</div>
            </div>

            <div className='nexus-mb-2'>
              <div className='title'> {t('customerPortal.notWorked')}</div>
              <div className='title'>
                <label className='primary-color'>
                  {policyEffectiveDate && moment(policyEffectiveDate).format('MMM YYYY')}
                </label>
                ?
              </div>
            </div>

            <div className='nexus-mb-2'>
              <div className='right-container'>
                {t('customerPortal.flexiblePremium')}{' '}
                <label className='primary-color'>
                  {policyEffectiveDate && moment(policyEffectiveDate).locale('de').format('MMM YYYY')}
                </label>
                {t('customerPortal.requestDiscount')}{' '}
                <label className='primary-color'>
                  {policyEffectiveDate && moment(policyEffectiveDate).add(41, 'days').format('MMM YYYY')}
                </label>{' '}
                {t('customerPortal.premiuminelevenDays')}{' '}
                <label className='primary-color'>
                  {' '}
                  {policyEffectiveDate && moment(policyEffectiveDate).add(41, 'days').format('MMM YYYY')}
                </label>
                .
              </div>
              <div className='nexus-mt-1 italic-text'>{t('customerPortal.daysRemaining')}</div>
            </div>

            <div className='right-buttons'>
              <div className='nexus-mb-2'>
                <Button type='primary' className={'transparent-button'} onPress={() => this.setShow(true)}>
                  {t('customerPortal.requestDiscountBtn')}
                </Button>
              </div>
              <div className='nexus-mb-2'>
                <Button type='primary' className={'transparent-button'} onPress={() => {}}>
                  {t('customerPortal.moreInfo')}
                </Button>
              </div>
            </div>
          </div>
          <NexusModal id='modal' show={showModal}>
            {/* <NexusModalHeader onCloseEvent={() => this.setShow(false)}>
              Success
              <NexusModalHeaderSubtitle>Placeholder</NexusModalHeaderSubtitle>
            </NexusModalHeader> */}
            <NexusModalBody>
              <p style={{ fontSize: '18px', lineHeight: '20px' }}>{t('customerPortal.policyDiscount')}</p>
            </NexusModalBody>
            <NexusModalFooter>
              <button onClick={() => this.setShow(false)} className='nexus-btn-primary'>
                Ok
              </button>
            </NexusModalFooter>
          </NexusModal>
        </div>
      </>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <CustomerPortal {...props} navigate={navigate} />;
}

const mapStateToProps = (state) => {
  const { userInfo, PolicyInfo } = state;
  return {
    userName: userInfo.userName,
    policyNumber: PolicyInfo.policyNumber,
  };
};
const CustomerWithLang = withTranslation('common')(WithNavigate);
export default connect(mapStateToProps)(CustomerWithLang);
