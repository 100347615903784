import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_EY_AD_CLIENT_ID,
    authority: process.env.REACT_APP_EY_AD_AUTHORITY,
    redirectUrl: 'https://nexusforinsurance-sbi-party.azurewebsites.net/#/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
// const client = new Msal.UserAgentApplication(msalConfig);
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],

  // redirectUri: 'http://localhost:3001/',

};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_EY_AD_GRAPH_END_POINT,
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: `${window.location.origin}/auth.html`,
};
export const authProvider = new MsalAuthProvider(msalConfig, loginRequest, options);
