import { Component } from "react";
import { useNavigate } from 'react-router-dom';
import { Header, Tab, NeedHelp } from '../../sharedComponents';
import '../../style/StepFourStyle.scss';
import { NexusFormField, NexusInput, NexusLabel } from '@nexus/react';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
class StepFour extends Component {
    state = {
      occupation : this.props.userOccupation,
      startDate: this.props.stepThree.policyStartDate,
    }
    navigateToNextPage() {
      this.props.navigate('/sbi/step-five');
    }
    
    render() {
        const {pricing,t} = this.props;
        console.log('pricing', pricing);
        console.log('this.props', this.props);
        return (
            <>
                <Header isBackButton={false} />
                <Tab activeTab={4} />
                <NeedHelp />
                <div className="step-4">
                    <div className="page-title">{t('step4.reviewQoute')}</div>
                    <div>
                        <NexusFormField>
                            <NexusLabel>{t('step4.coveredOccupation')} </NexusLabel>
                            <label className="link edit">{t('step4.edit')}</label>
                            <NexusInput value={t(`home.${this.props.userOccupation.toLowerCase()}`)} disabled oninput={(event) => this.setState({occupation : event.target.value})}></NexusInput>
                        </NexusFormField>
                        <NexusFormField>
                            <NexusLabel>{t('step4.policyStartDate')}</NexusLabel>
                            <label className="link edit">{t('step4.edit')}</label>
                            <NexusInput disabled type="date" value={this.props.stepThree.policyStartDate} oninput={(event) => this.setState({startDate : event.target.value})}></NexusInput>
                        </NexusFormField>
                        <div className="italic-text">{t('step4.policyRenew')}</div>
                        <NexusLabel>{t('step4.monthlyPremium')}</NexusLabel>
                        {pricing && pricing?.grossPremium && <div className="amount">$ {Math.round(pricing.grossPremium/ 12)} <label className="decimal">.00</label></div>}
                        <button className="nexus-btn grey-btn">{t('step4.reviewGeneral')}</button>
                        <div className="bottom-label">
                            <NexusLabel>{t('step4.additionalInsured')}</NexusLabel>
                            <div className="link insured-link">{t('step4.clickHere')}</div>
                        </div>
                        <button onClick={() => this.navigateToNextPage()} className="nexus-btn blue-btn">{t('step4.proccedToPay')}</button>
                    </div>
                </div>
            </>
        );
    }
};

function StepFourNavigate(props) {
    let navigate = useNavigate();
    return <StepFour {...props} navigate={navigate} />
}


const mapStateToProps = (state) => {
    const { userInfo } = state;
    return {
        userOccupation: userInfo.userOccupation,
        stepThree: userInfo.stepThree,
        pricing: userInfo.pricing
    };
  };

  const StepFourWithLang = withTranslation('common')(StepFourNavigate);

  export default connect(mapStateToProps)(StepFourWithLang);