import { Component } from "react";
import { Header, Button } from '../../sharedComponents';
import '../../style/Home.scss';
import {
  Accountant,
  CooperatorLogo,
  Electrician,
  Plumber,
  Flag,
  Canada,
} from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
import moment from 'moment';
import 'moment/locale/fr';
class Home extends Component {  
  navigateToNextPage(path){
    this.props.navigate(path);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: 'RESET_ALL_USER_INFO_STATES', payload: {} });
  }

  changeLanguage(){
    const { i18n } = this.props;
    let language = i18n.language == 'fr' ? 'en' : 'fr';
    i18n.changeLanguage(language);
    moment.locale(language);
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <Header hideLogo={true} isBackButton={false} />
        <div className="home-page">
          <div className="top-wrapper">
            <img src={CooperatorLogo} className='cop-logo' alt="CooperatorLogo" />
            {/* <div className="flag-container"> */}
            <div className="flag-container" onClick={()=>this.changeLanguage()}>
              <img src={i18n.language == 'en' ? Flag : Canada} />
              <label className="camalize">{i18n.language == 'en' ? 'Fr' : 'En' }</label>
            </div>
          
            <div className="coverage-text-wrapper">
              <div className="coverage-text">
               {t('home.title')}
              </div>
              <Button type="primary" onPress={()=> this.navigateToNextPage('/sbi/step-one')}>
               {t('home.getQoute')}
              </Button>
            </div>
          </div>
          <div className="middle-card">
            <div className="col">
              <img src={Accountant} alt=''/>
              <div className="col-title"> {t('home.accountant')}</div>
              {/* <div className="col-sub-tile">{t('home.accountantSubTitle')}</div> */}
            </div>
            <div className="vertical-line"/>
            <div className="col">
              <img src={Electrician} alt=''/>
              <div className="col-title">{t('home.electrician')}</div>
              {/* <div className="col-sub-tile">{t('home.electricianSubTitle')}</div> */}
            </div>
            <div className="vertical-line"/>
            <div className="col">
              <img src={Plumber} alt=''/>
              <div className="col-title">{t('home.plumber')}</div>
              {/* <div className="col-sub-tile">{t('home.plumberSubTitle')}</div> */}
            </div>
          </div>
          <div className="bottom-wrapper">

          <div className="bottom-blue-cover">
            <div className="button-wrapper">
              <Button className={'transparent-button'} type="secondary" onPress={()=>this.navigateToNextPage('/sbi/login')}>{t('home.login')}</Button>
              <Button className={'transparent-button'} type="secondary" onPress={()=>this.navigateToNextPage('/sbi/sign-up')}>{t('home.createAccount')}</Button>
            </div>
           <div className="bottom-text">
            {t('home.bottomText')}
          </div>
          </div> 
          </div> 
        </div>  
      </>
    );
  }
};

function HomeWithNavigate(props) {
  let navigate = useNavigate();
  return <Home {...props} navigate={navigate} />
}

const HomeWithLang = withTranslation('common')(HomeWithNavigate);
export default connect()(HomeWithLang);
