import { Component } from 'react';
import { Header, Tab, Radio, NeedHelp } from '../../sharedComponents';
import '../../style/StepTwoStyle.scss';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
class StepTwoAccountant extends Component {
  state = {
    qualified: null,
    eligibility: null,
  };
  navigateToNextPage() {

    const { dispatch } = this.props;
    dispatch({
      type: 'UPDATE_STEP_TWO_ACCOUNTANT',
      payload: this.state.qualified==="true" ? true: false,
    });

    this.props.navigate('/sbi/step-three');
  }
  navigateToPrevPage() {
    this.props.navigate('/sbi/step-one');
  }
  render() {
    const { qualified, eligibility } = this.state;
    const { t } = this.props;
    return (
      <>
        <Header isBackButton={false} />
        <Tab activeTab={2} />
        <NeedHelp />
        <div className='step-2'>
          <div>
            <div className='page-title'>{t('step2.infoRequired')}</div>
            <div className='check-box-container'>
              <div className='page-content'>{t('step2.qualifiedCPA')}</div>
              <div className='radio-container'>
                <Radio
                  value={true}
                  label={t('common.yes')}
                  name='qualified'
                  onChange={(event) => {
                    this.setState({ qualified: event.target.value });
                  }}
                />
                <Radio
                  value={false}
                  label={t('common.no')}
                  name='qualified'
                  onChange={(event) => {
                    this.setState({ qualified: event.target.value });
                  }}
                />
              </div>
              {qualified && qualified == 'true' && (
                <div className={`policy-status green`}>{t('step2.qualified')}</div>
              )}
            </div>
          </div>
          <div>
            <div className='page-sub-title'>{t('step2.confirmEligibility')}</div>
            <div className='page-content'>
              <div>{t('step2.lastRevenue')}.</div>
              <div>{t('step2.clientsOutside')}</div>
              <div>{t('step2.subContract')}</div>
              <div>{t('step2.legalProcceeding')}</div>
              <div>{t('step2.policyCancelled')}</div>
              <div>{t('step2.verbalAbuse')}</div>
              <div>{t('step2.provideEvidence')}</div>
              <div>{t('step2.pastClaims')}</div>
            </div>
            <div className='radio-container'>
              <Radio
                value={true}
                label={t('common.yes')}
                name='eligibility'
                onChange={(event) => {
                  this.setState({ eligibility: event.target.value });
                }}
              />
              <Radio
                value={false}
                label={t('common.no')}
                name='eligibility'
                onChange={(event) => {
                  this.setState({ eligibility: event.target.value });
                }}
              />
            </div>
            {eligibility && eligibility == 'false' && (
              <div className={`policy-status red`}>
                {t('step2.cantOfferPolicy')}
              </div>
            )}
            <div className='nexus-row  nexus-mt-4'>
              <div className='nexus-col-sm-1 nexus-col-lg-2'>
                <button onClick={() => this.navigateToPrevPage()} className='nexus-btn'>
                {t('common.previous')}
                </button>
              </div>
              <div className='nexus-col-sm-1 nexus-col-lg-2'>
                <button
                  disabled={eligibility === 'false' ? true : false}
                  onClick={() => this.navigateToNextPage()}
                  className='nexus-btn-primary'
                >
                    {t('common.next')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function StepTwoAccountantNavigation(props) {
  let navigate = useNavigate();
  return <StepTwoAccountant {...props} navigate={navigate} />;
}

const StepTwoWithLang = withTranslation('common')(StepTwoAccountantNavigation);

export default connect()(StepTwoWithLang);

