import './style.scss';
import { NexusRadio } from '@nexus/react';

const Radio = ({ onChange, label, value, checked, name }) => {
    return (
        <div className="radio-button">
            <NexusRadio
                name={name}
                value={value}
                checked={checked}
                onchange={onChange}
            >
                {label}
            </NexusRadio>
        </div>
    );
}


export default Radio;