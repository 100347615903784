import './style.scss';
import { CooperatorLogo, Search, Menu, Avatar, ArrowWhite } from '../../../assets/icons/';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
  const { userName, hideLogo } = props;
  const {t} = useTranslation('common');
  return (
    <div className='app-header flex-row align-items-center'>
      { !hideLogo && <img src={CooperatorLogo} className='cooperatorLogo' alt='Search' />}
      {props.profile && (
        <div class="dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div className='nexus-row nexus-middle-sm nexus-mr-4'>
              <img src={Avatar} className='avatar-icon' alt='avatar' />
              <div className='profile-name'>{t('common.welcome')} {userName}</div>
              <img src={ArrowWhite} className='arrow-icon' alt='arrow' />
            </div>
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" href="#">{t('common.logout')}</a></li>
          </ul>
        </div>

      )}

      <img src={Search} className='right-icon' alt='Search' />
      <div className='vertical-line' />
      <img src={Menu} className='right-icon' alt='Menu' />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state;
  return {
    userName: userInfo.userName,
  };
};

export default connect(mapStateToProps)(Header);