import { Component } from "react";
import { useNavigate } from 'react-router-dom';
import { Header } from '../../sharedComponents';
import '../../style/Success.scss';
import { CheckCircle } from '../../../assets/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import {withTranslation} from "react-i18next";
class Success extends Component {
    state = {
        occupation: 'Accountant',
        startDate: '2022-01-24'
    }
    
    expiryDate(){
        return expiryDate = moment(new Date(policyEffectiveDate)).add(365 , 'day').format('DD MMM YYYY');
    }
    render() {
        const {policyNumber, grossPremium,policyEffectiveDate} = this.props.policy;
        const expiryDate = moment(new Date(policyEffectiveDate)).add(365 , 'day').format('DD MMM YYYY');
        const {t} = this.props;
        return (
            <>
                <Header isBackButton={false} />
                <div className="success-screen">
                   <div className="congrats-message">
                       <div>{t('success.congrats')}</div>
                       <div>{t('success.insurance')}</div>
                   </div>
                   <div className="dialogue-popup">
                       <img src={CheckCircle} className="check-circle"/>
                       <div className="title my-4">{t('success.paymentSuccessFull')}</div>
                       <div className="popup-rows my-4">
                           <div className="d-flex flex-row">
                               <div className="popup-row-label">{t('success.policyNumber')}</div>
                               {policyNumber&&<div>{policyNumber}</div>}
                           </div>
                           <div className="d-flex flex-row">
                               <div className="popup-row-label">{t('success.policyPremium')}</div>
                               {grossPremium && <div>$ {Math.round(grossPremium/ 12).toFixed(2)}</div>}
                           </div>
                           <div className="d-flex flex-row">
                               <div className="popup-row-label">{t('success.policyExpiration')}</div>
                              {expiryDate&& <div>{expiryDate}</div>}
                           </div>
                       </div>
                       <div className="mb-2">
                         <button className="nexus-btn blue-btn">{t('success.print')}</button>
                         <button className="nexus-btn transparent-btn" onClick={()=>this.props.navigate('/')}>{t('success.close')}</button>
                       </div>
                   </div>
                   <div className="button-container">
                       <button className="nexus-btn transparent-btn"  onClick={()=>this.props.navigate('/sbi/login')}>{t('success.signIn')}</button>
                       <button className="nexus-btn blue-btn"  onClick={()=>this.props.navigate('/sbi/sign-up')}>{t('success.register')}</button>
                   </div>
                </div>
            </>
        );
    }
};


function SuccessWithNav(props) {
    let navigate = useNavigate();
    return <Success {...props} navigate={navigate} />
}


const mapStateToProps = (state) => {
    const { userInfo } = state;
    return {
        userOccupation: userInfo.userOccupation,
        stepThree: userInfo.stepThree,
        policy: userInfo.policy
    };
  };

  const SuccessLang = withTranslation('common')(SuccessWithNav);
  export default connect(mapStateToProps)(SuccessLang);

