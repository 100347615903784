import React from "react";
import './style.scss';
import { useTranslation } from 'react-i18next';

const Tab = ({ activeTab }) => {
  const {t} = useTranslation('common');
    return (
        <div className="page-tab">
            { [...Array(5)].map((_, index) => 
              <div key={index} className={'tab-link'} onClick={()=>{ handleNavigation(index) }}>
                <div className="tab-text">
                  {t(`common.step${index+1}`)}
                  {activeTab === index+1 && <div className="highlight" />}
                </div>
               
              </div>
            ) }
        </div>
    );
}

export default Tab ;