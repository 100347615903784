import { Component } from 'react';
import { Header, Tab, NeedHelp } from '../../sharedComponents';
import '../../style/StepFive.scss';
import { NexusFormField, NexusIcon, NexusInput, NexusLabel } from '@nexus/react';
import { useNavigate } from 'react-router-dom';
import Ic_credit_card_24px from '@nexus/core/dist/assets/icons/action/ic_credit_card_24px.svg';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";

class StepFive extends Component {
  state = {
    cardholderName: '',
    cardNumber: '',
    validThru: '',
    CVV: '',
  };


  setValue = (name, val) => {
    this.setState({
      [name]: val,
    });
  };

  navigateToNextPage() {
    this.props.navigate('/sbi/payment-spinner');
  }
  navigateToPrevPage() {
    this.props.navigate('/sbi/step-four');
  }

  render() {
    const { cardholderName, cardNumber, validThru, CVV } = this.state;
    const { grossPremium } = this.props.pricing;
    const {t} = this.props;
    return (
      <>
        <Header isBackButton={false} />
        <Tab activeTab={5} />
        <NeedHelp />
        <div className='step-one-container'>
          <div className='nexus-row step-one'>
            <div className='nexus-col-xs-4'>
              <div className='page-title'>{t('step5.paymentByCard')}</div>
            </div>
            <div className='nexus-col-xs-4 nexus-mt-2'>
              <div className='page-sub-title'>{t('step5.amount')}</div>
              <div className='page-sub-title'>$ {Math.round(grossPremium/ 12).toFixed(2)}</div>
            </div>
            <div className='nexus-col-xs-4 nexus-mt-2 card-heading'>
              <div className='page-sub-title'>{t('step5.cardDetails')}</div>
              <NexusIcon className='card-icon' src={Ic_credit_card_24px}></NexusIcon>
            </div>
            <div className='nexus-col-xs-4 nexus-col-lg-6 nexus-mt-2 nexus-ml-2'>
              <div className='nexus-row card-input-container'>
                <div className='nexus-col-xs-4'>
                  <NexusFormField>
                    <NexusLabel>{t('step5.cardholderName')}</NexusLabel>
                    <NexusInput
                      value={cardholderName}
                      oninput={(event) => this.setValue('cardholderName', event.target.value)}
                    ></NexusInput>
                  </NexusFormField>
                </div>
                <div className='nexus-col-xs-4'>
                  <NexusFormField>
                    <NexusLabel>{t('step5.cardNumber')}</NexusLabel>
                    <NexusInput
                      type='number'
                      value={cardNumber}
                      oninput={(event) => this.setValue('cardNumber', event.target.value)}
                    ></NexusInput>
                  </NexusFormField>
                </div>
                <div className='nexus-col-xs-4 card-cvv'>
                  <NexusFormField>
                    <NexusLabel>{t('step5.validThru')}</NexusLabel>
                    <NexusInput
                      type='number'
                      value={validThru}
                      oninput={(event) => this.setValue('validThru', event.target.value)}
                    ></NexusInput>
                  </NexusFormField>
                  <NexusFormField className='nexus-ml-1'>
                    <NexusLabel>{t('step5.cvv')}</NexusLabel>
                    <NexusInput
                      type='number'
                      value={CVV}
                      oninput={(event) => this.setValue('CVV', event.target.value)}
                    ></NexusInput>
                  </NexusFormField>
                </div>
              </div>
            </div>
            <div className='nexus-col-xs-4 nexus-mt-4'>
              <button onClick={() => this.navigateToNextPage()} className='nexus-btn-primary nexus-ml-1'>
                {t('step5.payNow')}
              </button>
              <button onClick={() => this.navigateToPrevPage()} className='nexus-btn nexus-ml-5'>
                {t('step5.cancel')}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function StepFiveNavigation(props) {
  let navigate = useNavigate();
  return <StepFive {...props} navigate={navigate} />;
}

const mapStateToProps = (state) => {
  const { userInfo } = state;
  return {
    pricing: userInfo.pricing,
  };
};

const FiveWithLang = withTranslation('common')(StepFiveNavigation);

export default connect(mapStateToProps)(FiveWithLang);
