import React from "react";
import './style.scss';
import { Help } from '../../../assets/icons/';
import {useTranslation} from "react-i18next";

const NeedHelp = ({onPress}) => {
    const {t} = useTranslation('common');
    return (
        <div className='help flex-column align-items-center'>
            <img src={Help} className='help-icon' alt="Help" />
            <div className="need-help-text">{t('common.needHelp')}</div>
        </div>
    );
}


export default NeedHelp;