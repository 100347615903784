import { useState, forwardRef } from "react";
import useInterval from "./useInterval";


const Circle = ({ colour, percentage }) => {
  const r = 27;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      r={r}
      cx={150}
      cy={50}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={"8px"}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    ></circle>
  );
};

const Text = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontFamily="EYInterstate Regular"
      fontSize={"12px"}
      color="#2E2E38"
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

const ProgressCircle = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(0);

  // useInterval(() => {
  //   if(!loaded){
  //     if ( loading < 100) {
  //       setLoading(loading + 1);
  //     } 
  //     else {
  //       setLoaded(true);
  //       setTimeout(() => {
  //         props.doneLoading();
  //       }, 1000);
  //     }
  //   }
  // }, 100);

  useInterval(() => {
    if (props.isLoading && loading < 75) {
      setLoading(loading + 1);
    } else if (!props.isLoading && loading < 100) {
      setLoading(loading + 1);
      if (loading === 99) {
        setTimeout(() => {
          props.doneLoading();
        }, 1000);
      }
    }
  }, 100);



  return (
    <svg width={100} height={100}>
      <g transform={`rotate(-90 ${"100 100"})`}>
        <Circle colour="#E7E7EA" />
        <Circle colour={'#0071B3'} percentage={loading} />
      </g>
      <Text percentage={loading} />
    </svg>
  );
});

export default ProgressCircle;