import './style.scss';

const Button = ({onPress, children, type, className}) => {
    return (
      <div className={`${className} app-button`}>
        <button className={`nexus-btn ${type==='primary' ? "primary": "secondary"}`} onClick={onPress}>
          {children}
        </button>
      </div>
    );
}


export default Button;